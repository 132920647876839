/* eslint-env node, browser */
'use strict'

/**
 * resets the library back to its initial state. This is useful
 * if you want to clear out the current keyboard shortcuts and bind
 * new ones - for example if you switch to another page
 *
 * @returns void
 */
module.exports = function () {
  var self = this
  self.callbacks = {}
  self.directMap = {}
  return this
}
