/* eslint-env node, browser */
'use strict'

/**
 * prevents default for this event
 *
 * @param {Event} e
 * @returns void
 */
module.exports = function (e) {
  if (e.preventDefault) {
    e.preventDefault()
    return
  }

  e.returnValue = false
}
